<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "CustomButtons.NewButton",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        @methodSave="onSubmit('0', 'btn-save')"
        @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
        @clear="onClear()"
        :isNewRecord="true"
      />
    </div>
  </div>
  <form class="form-custom-button-new pe-2 ps-2" novalidate>
    <div v-if="errors.length > 0" class="alert alert-warning">
      <ul class="mb-0">
        <li v-for="error in errors" v-bind:key="error">
          {{ error }}
        </li>
      </ul>
    </div>
    <div v-if="isSuccess" class="alert alert-success">
      {{
        $t(
          "Messages.SuccessMessage",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-6">
        <label for="Name" class="form-label required"
          >{{
            $t(
              "CustomButtons.Name",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          @keyup="buttonNameKeyup()"
          id="Name"
          v-model="buttonData.Name"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-6">
        <label for="FormulaName" class="form-label required">{{
          $t(
            "CustomButtons.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          type="text"
          class="form-control"
          id="FormulaName"
          v-model="buttonData.FormulaName"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-12 mt-2">
        <label for="Description" class="form-label">{{
          $t(
            "CustomButtons.Description",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <textarea
          name="Description"
          class="form-control"
          id="Description"
          v-model="buttonData.Description"
          :spellcheck="this.$isTextSpellCheck"
          rows="1"
        ></textarea>
      </div>
      <div class="col col-md-6 mt-2">
        <label for="ButtonType" class="form-label required">{{
          $t(
            "CustomButtons.ButtonType",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          @onChange="onChangeButtonType"
          :data="buttonTypeData"
          :allowEmpty="false"
        />
      </div>
      <div class="col col-md-6 mt-2">
        <label for="ForwardType" class="form-label required">{{
          $t(
            "CustomButtons.ForwardType",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          @onChange="onChangeForwardType"
          :data="forwardTypeData"
          :resetSelect="resetSelect"
          :allowEmpty="false"
          :isDisabled="String.isNullOrWhiteSpace(buttonData.ButtonTypeId)"
        />
      </div>
      <div v-if="buttonData.ButtonTypeId == 4" class="col-md-6 mt-2">
        <label for="formTemplates" class="form-label required"
          >{{
            $t(
              "CustomObjects.FormTemplates",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeformTemplate"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditFormTemplate/{0}&formTemplateId=',
              this.$route.params.customObjectId
            )
          "
          :isParameters="true"
          :allowEmpty="false"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryFormTemplates?coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
      <div v-if="buttonData.ButtonTypeId == 1" class="col col-md-6 mt-2">
        <label for="BusinessRule" class="form-label required">{{
          $t(
            "CustomButtons.BusinessRule",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeBusinessRule"
          :allowEmpty="false"
          :isDetailIconShow="false"
          :isGatewayRequest="true"
          :isParameters="true"
          :requestUrl="
            String.format(
              '/Brs-BusinessRulesOfCustomObjectWithFormula?customObjectFormulaName={0}',
              this.buttonData.customObjectFormulaName
            )
          "
        />
      </div>
      <div v-if="buttonData.ButtonTypeId == 2" class="col col-md-6 mt-2">
        <label for="StaticLink" class="form-label required">{{
          $t(
            "CustomButtons.StaticLink",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          type="text"
          class="form-control"
          id="StaticLink"
          v-model="buttonData.StaticLink"
          :spellcheck="this.$isTextSpellCheck"
          :autocomplete="this.$isAutoComplete"
          required
        />
      </div>
      <div v-if="buttonData.ButtonTypeId == 3" class="col col-md-6 mt-2">
        <label for="Field" class="form-label required">{{
          $t(
            "CustomButtons.Field",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeField"
          :allowEmpty="false"
          :isDetailIconShow="true"
          selectedPrimaryKey="publicId"
          :routeLink="
            String.format(
              '#/CustomObject/EditField/{0}&fieldId=',
              this.$route.params.customObjectId
            )
          "
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryAllTextFieldsWithFormula?parentFormulaName={0}',
              this.buttonData.customObjectFormulaName
            )
          "
        />
      </div>
      <div v-if="buttonData.ForwardTypeId == 3" class="col col-md-6 mt-2">
        <label for="Slider" class="form-label"
          >{{
            $t(
              "CustomButtons.LightboxRate",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <h6 class="badge badge-warning text-dark ms-1">
          {{ buttonData.lightBoxRatio }}
        </h6>
        <input
          type="range"
          class="form-range"
          min="1"
          max="100"
          v-model="buttonData.lightBoxRatio"
          @oninput="this.buttonData.lightBoxRatio = this.value"
        />
      </div>
    </div>
    <div class="col-12 mt-3">
      <div class="form-check">
        <label for="IsActive" class="form-label">{{
          $t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsActive"
          v-model="buttonData.isActive"
        />
      </div>
      <div class="form-check">
        <label for="IsIconViewEnabled" class="form-label">{{
          $t(
            "CustomButtons.IsIconViewEnabled",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsIconViewEnabled"
          v-model="buttonData.IsIconViewEnabled"
        />
      </div>
      <div class="card mt-2" v-if="buttonData.IsIconViewEnabled">
        <div class="card-body">
          <h3 class="card-title">
            <i class="bi bi-sliders"></i>
            {{
              $t(
                "CustomButtons.IsIconViewEnabled",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h3>
          <div class="row align-items-end">
            <div class="col-6">
              <label for="Icon" class="form-label"
                >{{
                  $t(
                    "CustomButtons.Icon",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <icon-picker
                v-model="buttonData.icon"
                :allIconsText="
                  $t(
                    'Components.IconPicker.AllIcons',
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                "
              />
            </div>
            <div class="col-6">
              <label for="IconColor" class="form-label"
                >{{
                  $t(
                    "CustomButtons.IconColor",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }} </label
              ><br />
              <ColorPicker @valueChanged="colorPickerChange" />
            </div>
          </div>
        </div>
      </div>
      <div class="form-check">
        <label for="IsShowApprovalMessage" class="form-label">{{
          $t(
            "CustomButtons.IsShowApprovalMessage",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsShowApprovalMessage"
          v-model="buttonData.isShowApprovalMessage"
        />
      </div>
      <div v-if="buttonData.isShowApprovalMessage" class="col col-md-6 mb-1">
        <label for="ApprovalMessageContent" class="form-label">{{
          $t(
            "CustomButtons.ApprovalMessageContent",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <textarea
          name="ApprovalMessageContent"
          class="form-control"
          id="ApprovalMessageContent"
          v-model="buttonData.approvalMessageContent"
          :spellcheck="this.$isTextSpellCheck"
          rows="1"
        ></textarea>
      </div>
      <div class="form-check" v-if="buttonData.ButtonTypeId == 3">
        <label for="SendDefaultValueWithButton" class="form-label">{{
          $t(
            "CustomButtons.SendDefaultValue",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="SendDefaultValueWithButton"
          v-model="buttonData.SendDefaultValueWithButton"
        />
      </div>
      <div
        class="form-check"
        v-if="
          (buttonData.ButtonTypeId == 1 && buttonData.ForwardTypeId == 3) ||
          (buttonData.ButtonTypeId == 3 && buttonData.ForwardTypeId == 3)
        "
      >
        <label for="IsPageRefreshAfterProcessDone" class="form-label">{{
          $t(
            "CustomButtons.IsPageRefreshAfterProcessDone",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsPageRefreshAfterProcessDone"
          v-model="buttonData.IsPageRefreshAfterProcessDone"
        />
      </div>
      <div class="form-check">
        <label
          for="IsPermissionApply"
          class="form-label col col-md-6"
          data-bs-toggle="tooltip"
          data-bs-placement="left"
          :title="
            this.$t(
              'CustomButtons.ValidationTooltip',
              {},
              { locale: this.$store.state.activeLang }
            )
          "
          >{{
            $t(
              "CustomButtons.IsValidationApply",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label
        >
        <input
          class="form-check-input"
          type="checkbox"
          id="IsPermissionApply"
          v-model="buttonData.isPermissionApply"
          @click="isCheckIsPermissionApply(buttonData.isPermissionApply)"
        />
      </div>

      <div v-if="this.isFormula" class="col col-md-6">
        <label for="Validation" class="form-label required">{{
          $t(
            "CustomButtons.Validation",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          :allowEmpty="false"
          :resetSelect="selectsReset"
          :isParameters="true"
          :isGatewayRequest="true"
          openDirection="top"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryButtonValidations?customObjectId={0}',
              this.$route.params.customObjectId
            )
          "
          @onChange="onChangeValidation"
        />
      </div>
    </div>
    <div class="card mt-2" v-if="buttonData.SendDefaultValueWithButton">
      <div class="card-body">
        <h3 class="card-title">
          <i class="bi bi-sliders"></i>
          {{
            $t(
              "CustomButtons.SendDefaultValue",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </h3>
        <div class="row align-items-end">
          <div class="col-6">
            <label for="SendDefaultValueObject" class="form-label required"
              >{{
                $t(
                  "CustomButtons.SendDefaultValueObject",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </label>
            <FormSelect
              type="SelectWithRemoteDataAndInternalSearch"
              @onChange="onChangeForActionCustomObject"
              :allowEmpty="false"
              :isDetailIconShow="true"
              :routeLink="
                String.format(
                  '#/CustomObject/Detail/{0}',
                  this.$route.params.customObjectId
                )
              "
              :isGatewayRequest="true"
              :requestUrl="
                String.format(
                  '/Lcdp-SummaryCustomObjectsWithFormula',
                  this.$route.params.customObjectFormulaName
                )
              "
            />
          </div>
          <div class="col-6">
            <button
              type="button"
              style="display: none"
              class="btn btn-success getOtherFields float-end"
              id="getOtherFields"
              @click="getOtherFieldsAction"
            >
              <i class="bi bi-plus"></i>
              {{
                $t(
                  "BusinessRuleActions.GetOtherFields",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </div>
        </div>
        <div
          class="col col-md-12 mt-3"
          v-if="
            fieldList.length > 0 &&
            !String.isNullOrWhiteSpace(
              this.buttonData.SendDefaultValueCustomObjectPublicId
            )
          "
        >
          <Grid
            :settings="gridSettings"
            :columns="gridColumns"
            :rows="fieldList"
            @onFormulaEditorButtonClick="onFormulaEditorButtonClick"
          />
        </div>
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-body">
        <h3 class="card-title">
          <i class="bi bi-ui-checks"></i>
          {{
            $t(
              "CustomButtons.AppearanceSettings",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </h3>
        <div class="row mt-3">
          <div class="col-md-4">
            <div class="form-check">
              <label for="ShowAtDetailPage" class="form-label">{{
                $t(
                  "CustomButtons.ShowAtDetailPage",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                class="form-check-input"
                type="checkbox"
                id="ShowAtDetailPage"
                v-model="buttonData.showAtDetailPage"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-check">
              <label for="ShowAtNewPage" class="form-label">{{
                $t(
                  "CustomButtons.ShowAtNewPage",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                class="form-check-input"
                type="checkbox"
                id="ShowAtNewPage"
                v-model="buttonData.showAtNewPage"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-check">
              <label for="ShowAtEditPage" class="form-label">{{
                $t(
                  "CustomButtons.ShowAtEditPage",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                class="form-check-input"
                type="checkbox"
                id="ShowAtEditPage"
                v-model="buttonData.showAtEditPage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <ActionButtons
      v-if="$isMobile()"
      classes="mt-2"
      @methodSave="onSubmit('0', 'btn-save')"
      @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
      @clear="onClear()"
      :isNewRecord="true"
    />
  </form>
  <FormulaEditorModal
    :formulaField="formulaEditorFieldDto"
    @updateFormula="updateFormula"
  />
</template>
<script>
import $ from "jquery";
import IconPicker from "@/components/custom/icon-picker/IconPicker";

import FormulaEditorModal from "@/components/custom/formula-editor/FormulaEditorModal.vue";

export default {
  name: "CustomObjectNewButton",
  components: {
    IconPicker,
    FormulaEditorModal,
  },
  data() {
    return {
      resetSelect: false,
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      isFormula: false,
      errors: [],
      buttonData: {
        isActive: true,
        customObjectPublicId: this.$route.params.customObjectId,
        customObjectFormulaName:
          (this.$store.state.customObjectDto &&
            this.$store.state.customObjectDto.formulaName) ||
          null,
        lightBoxRatio: 80,
        isPermissionApply: false,
      },
      isSuccess: false,
      buttonTypeData: [
        {
          key: "1",
          value: this.$t(
            "CustomButtons.BusinessRuleButton",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "2",
          value: this.$t(
            "CustomButtons.DirectForwardButton",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "3",
          value: this.$t(
            "CustomButtons.CustomForwardButton",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "4",
          value: this.$t(
            "CustomButtons.FormTemplateButton",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      forwardTypeData: [],
      allForwardTypesData: [
        {
          key: "1",
          value: this.$t(
            "CustomButtons.DirectForward",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "2",
          value: this.$t(
            "CustomButtons.NewTab",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "3",
          value: this.$t(
            "CustomButtons.LightBox",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      forwardTypeDataOnlyLightBox: [
        {
          key: "3",
          value: this.$t(
            "CustomButtons.LightBox",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      fieldList: [],
      orjFieldList: [],
      fieldValueFormulas: [],
      formulaEditorFieldDto: null,
      gridSettings: {
        action: "NewButtonFields",
        requestUrl: String.format(""),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [],

        allowSearchPanel: true,
        allowDragAndDrop: false,
        allowPaging: false,
        allowDeleting: false,
        autoOrderProcess: true,

        buttons: [
          {
            name: "formula",
            cssClass: "btn-primary btn-formula-editor",
            iconClass: "sx-icon",
            routeButton: false,
            emitMethodName: "onFormulaEditorButtonClick",
            attributes: {
              "data-bs-toggle": "modal",
              "data-bs-target": "#modalFormulaEditor",
            },
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "Fields.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Fields.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formulaName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Fields.IsRequired",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isRequired",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Fields.Formula",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formula",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
      ],
    };
  },
  methods: {
    onFormulaEditorButtonClick(rowData) {
      this.formulaEditorFieldDto = rowData;
    },
    isCheckIsPermissionApply(selected) {
      if (selected == false) {
        this.isFormula = true;
        this.buttonData.validationName = "";
        this.buttonData.validationPublicId = "";
      } else {
        this.isFormula = false;
      }
    },
    updateFormula(formula, field) {
      this.formulaEditorFieldDto.formula = formula;

      var gridFieldIndex = this.fieldList.findIndex(
        (f) => f.publicId == field.publicId
      );
      var gridData = this.fieldList;
      gridData[gridFieldIndex].formula = formula;

      this.fieldList = [];
      this.fieldList = gridData;

      if (
        this.buttonData.fieldValueFormulas.some((l) => l.key == field.publicId)
      ) {
        if (String.isNullOrWhiteSpace(formula)) {
          this.buttonData.fieldValueFormulas =
            this.buttonData.fieldValueFormulas.filter(function (f) {
              return f.key !== field.publicId;
            });
        } else {
          this.buttonData.fieldValueFormulas =
            this.buttonData.fieldValueFormulas.filter(function (f) {
              return f.key !== field.publicId;
            });

          this.buttonData.fieldValueFormulas.push({
            value: formula,
            key: field.publicId,
          });
        }
      } else {
        if (!String.isNullOrWhiteSpace(formula)) {
          this.buttonData.fieldValueFormulas.push({
            value: formula,
            key: field.publicId,
          });
        }
      }
    },
    onClear() {
      this.buttonData = {
        isActive: true,
        isMain: true,
        isFormTemplateButtonActive: true,
        isDeleteButtonActive: true,
        isEditButtonActive: true,
        isHistoryButtonActive: true,
        customObjectPublicId: this.buttonData.customObjectPublicId,
        customObjectFormulaName: this.buttonData.customObjectFormulaName,
      };
      $(".form-custom-button-new").removeClass("was-validated");
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-custom-button-new");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-ButtonNew", { ...this.buttonData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push(
                "/CustomObject/Buttons/" + this.$route.params.customObjectId
              );
            } else {
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
    onChangeButtonType(selected) {
      this.buttonData.fieldPublicId = null;
      this.buttonData.fieldFormulaName = null;
      this.buttonData.fieldName = null;
      this.buttonData.ButtonTypeId = selected.key;
      this.resetSelect = !this.resetSelect;

      this.buttonData.SendDefaultValueWithButton = false;
      this.buttonData.SendDefaultValueCustomObjectFormulaName = null;
      this.buttonData.SendDefaultValueCustomObjectPublicId = null;
      this.buttonData.SendDefaultValueCustomObjectName = null;
      this.buttonData.fieldValueFormulas = [];

      if (this.buttonData.ButtonTypeId == 1) {
        this.forwardTypeData = this.forwardTypeDataOnlyLightBox;
      } else {
        this.forwardTypeData = this.allForwardTypesData;
      }
    },
    onChangeForwardType(selected) {
      this.buttonData.ForwardTypeId = selected.key;
    },
    onChangeField(selected) {
      this.buttonData.fieldPublicId = selected.publicId;
      this.buttonData.fieldFormulaName = selected.key;
      this.buttonData.fieldName = selected.value;
    },
    onChangeBusinessRule(selected) {
      this.buttonData.BusinessRuleFormulaName = selected.key;
      this.buttonData.BusinessRuleName = selected.value;
    },
    onChangeValidation: function (selected) {
      this.buttonData.validationPublicId = selected.key;
      this.buttonData.validationName = selected.value;
    },
    onChangeformTemplate: function (selected) {
      this.buttonData.formTemplatePublicId = selected.key;
      this.buttonData.formTemplateName = selected.value;
    },
    buttonNameKeyup: function () {
      if (String.isNullOrWhiteSpace(this.buttonData.Name)) {
        this.buttonData.FormulaName = "";
        return;
      }
      this.buttonData.FormulaName = String.replaceAlphanumeric(
        this.buttonData.Name,
        "_"
      ).toUpperCase();
    },
    colorPickerChange(value) {
      this.buttonData.iconColor = value;
    },
    onChangeForActionCustomObject: function (selected) {
      this.buttonData.SendDefaultValueCustomObjectFormulaName = selected.key;
      this.buttonData.SendDefaultValueCustomObjectPublicId = selected.publicId;
      this.buttonData.SendDefaultValueCustomObjectName = selected.value;
      if (selected.key == null) {
        $(".brTable").hide();
        $(".getOtherFields").hide();
      } else {
        this.buttonData.fieldValueFormulas = [];
        this.getFields(selected.key);
        $(".getOtherFields").show();
        $(".brTable").show();
      }
    },
    getOtherFieldsAction() {
      this.fieldList = this.orjFieldList.sort(function (a, b) {
        return b.isRequired - a.isRequired;
      });
      $(".getOtherFields").hide();
    },
    getFields(coId) {
      this.orjFieldList = [];
      this.fieldList = [];

      this.$prodGatewayAxios
        .get(String.format("/Lcdp-FieldList?customObjectPublicId={0}", coId))
        .then((response) => {
          var multipleSelect = this.multipleSelect,
            select = this.select,
            table = this.table,
            isMultiLanguage = this.$store.getters._isMultiLanguage;

          if (response.data.itemCount > 0) {
            response.data.items.forEach((v) => {
              v.MultipleSelectText = multipleSelect;
              v.SelectText = select;
              v.TableText = table;
              v.IsMultiLanguage = isMultiLanguage;
              v.formula = "";
            });
          }

          this.orjFieldList = response.data.items;
          this.fieldList = this.orjFieldList.filter(function (f) {
            return f.isRequired;
          });
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  watch: {
    "$store.state.customObjectDto"(newValue) {
      if (!String.isNullOrWhiteSpace(newValue)) {
        this.buttonData.customObjectPublicId =
          this.$store.state.customObjectDto.publicId;
        this.buttonData.customObjectFormulaName =
          this.$store.state.customObjectDto.formulaName;
      }
    },
  },
};
</script>
